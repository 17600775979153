import { render, staticRenderFns } from "./livex.vue?vue&type=template&id=e0fa1b8c&scoped=true"
import script from "./livex.vue?vue&type=script&lang=js"
export * from "./livex.vue?vue&type=script&lang=js"
import style0 from "./livex.vue?vue&type=style&index=0&id=e0fa1b8c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../.yarn/__virtual__/vue-loader-virtual-204c11ec9d/0/cache/vue-loader-npm-15.11.1-829412283a-509a816d45.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0fa1b8c",
  null
  
)

export default component.exports